.ant-checkbox-inner {
  border-radius: 4px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  border-radius: 2px;
}

.ant-checkbox-inner::after {
  top: 47%;
  left: 19%;
}

.@{class-prefix}-radio-group {
  overflow-x: auto;
  overflow-y: hidden;

  &:hover{
  overflow-x: scroll;
    &::-webkit-scrollbar {
        width: 10px;
        height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #393812;
      -webkit-border-radius: 1ex;
      -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }
  }

  &::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0.25);
    margin-top: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #393812;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  &.@{class-prefix}-radio-group-wrap{
    white-space: nowrap;
  } 

  .ant-radio-button-wrapper {
    border-radius: 8px;
    border-left-width: 1.02px;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    content: unset !important;
  }
}
