@font-face {
  font-family: "Rajdhani";
  src: url("../../fonts/Rajdhani-Light.ttf") format("truetype");
  font-weight: @font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: "Rajdhani";
  src: url("../../fonts/Rajdhani-Regular.ttf") format("truetype");
  font-weight: @font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: "Rajdhani";
  src: url("../../fonts/Rajdhani-Medium.ttf") format("truetype");
  font-weight: @font-weight-medium;
  font-style: normal;
}

@font-face {
  font-family: "Rajdhani";
  src: url("../../fonts/Rajdhani-SemiBold.ttf") format("truetype");
  font-weight: @font-weight-semiBold;
  font-style: normal;
}

@font-face {
  font-family: "Rajdhani";
  src: url("../../fonts/Rajdhani-Bold.ttf") format("truetype");
  font-weight: @font-weight-bold;
  font-style: normal;
}
