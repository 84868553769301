// Color Variables
@colors: ~"primary-1" @primary-1, ~"primary-2" @primary-2,
  ~"primary-3" @primary-3, ~"primary-4" @primary-4, ~"secondary-1" @secondary-1,
  ~"secondary-2" @secondary-2, ~"secondary-3" @secondary-3,
  ~"secondary-4" @secondary-4, ~"warning-1" @warning-1, ~"warning-2" @warning-2,
  ~"warning-3" @warning-3, ~"warning-4" @warning-4, ~"orange-1" @orange-1,
  ~"orange-2" @orange-2, ~"orange-3" @orange-3, ~"orange-4" @orange-4,
  ~"success-1" @success-1, ~"success-2" @success-2, ~"success-3" @success-3,
  ~"success-4" @success-4, ~"danger-1" @danger-1, ~"danger-2" @danger-2,
  ~"danger-3" @danger-3, ~"danger-4" @danger-4, ~"info-1" @info-1,
  ~"info-2" @info-2, ~"info-3" @info-3, ~"info-4" @info-4, ~"grey-1" @grey-1,
  ~"grey-2" @grey-2, ~"grey-3" @grey-3, ~"grey-4" @grey-4, ~"black-0" @b-0,
  ~"black-10" @b-10, ~"black-20" @b-20, ~"black-40" @b-40, ~"black-60" @b-60,
  ~"black-80" @b-80, ~"black-100" @b-100, ~"black-bg" @dark-bg,
  ~"primary-gradient" @primary-gradient,
  ~"secondary-gradient" @secondary-gradient,
  ~"warning-gradient" @warning-gradient, ~"dark-gradient" @dark-gradient,
  ~"success-gradient" @success-gradient, ~"danger-gradient" @danger-gradient,
  ~"info-gradient" @info-gradient;

// Text Color Hover Map
.text-color-hover-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-hover-@{prefix}-color-@{key} {
      &:hover {
        color: @value !important;
      }
    }
  }
}

.text-color-hover-classes(text, @colors);

// Text Color Map
.text-color-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-@{prefix}-color-@{key} {
      color: @value !important;
    }
  }
}

.text-color-classes(text, @colors);

// Background Color Hover Map
.bg-color-hover-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-hover-@{prefix}-color-@{key} {
      &:hover {
        background-color: @value !important;
      }
    }
  }
}

.bg-color-hover-classes(bg, @colors);

// Background Color Map
.bg-color-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-@{prefix}-color-@{key} {
      background-color: @value !important;
    }
  }
}

.bg-color-classes(bg, @colors);

// Background Hover Map
.bg-hover-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-hover-@{prefix}-@{key} {
      &:hover {
        background: @value !important;
      }
    }
  }
}

.bg-hover-classes(bg, @colors);

// Background Map
.bg-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-@{prefix}-@{key} {
      background: @value !important;
    }
  }
}

.bg-classes(bg, @colors);

// Border Color Hover Map
.border-color-hover-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-hover-@{prefix}-color-@{key} {
      &:hover {
        border-color: @value !important;
      }
    }
  }
}

.border-color-hover-classes(border, @colors);

// Border Color Map
.border-color-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-@{prefix}-color-@{key} {
      border-color: @value !important;
    }
  }
}

.border-color-classes(border, @colors);

// Fill Map
.fill-color-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-@{prefix}-@{key} {
      path,
      rect,
      line,
      circle {
        fill: @value !important;
      }
    }
  }
}

.fill-color-classes(fill, @colors);

// Fill Hover Map
.fill-hover-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-hover-@{prefix}-@{key} {
      &:hover {
        path,
        rect,
        line,
        circle {
          fill: @value !important;
        }
      }
    }
  }
}

.fill-hover-classes(fill, @colors);

// Stroke Map
.stroke-color-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-@{prefix}-@{key} {
      path,
      rect,
      line,
      circle {
        stroke: @value !important;
      }
    }
  }
}

.stroke-color-classes(stroke, @colors);

// Stroke Hover Map
.stroke-hover-classes(@prefix, @list) {
  .iter(length(@list));

  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);

    .@{class-prefix}-hover-@{prefix}-@{key} {
      &:hover {
        path,
        rect,
        line,
        circle {
          stroke: @value !important;
        }
      }
    }
  }
}

.stroke-hover-classes(stroke, @colors);
