

@media @xl {
    .@{class-prefix}-mt-lg-30{
        margin-top: 30px !important;
    }
}

.@{class-prefix}-mt-lg-30{
    margin-top: unset;
}

// React Quill Dark
body.dark {
    .ql-snow.ql-toolbar button{
        color: fff;
    }

    .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
        background-color: #000!important;
    }
    .ql-snow .ql-picker {
        color: #fff !important;
    }

    button .ql-stroke,
    .ql-picker-label, .ql-stroke {
    fill: none;
    stroke: #fff !important;
    }

    .ql-active .ql-stroke {
    fill: none;
    stroke: #fff!important;
    }

    button:hover .ql-fill,
    .ql-picker-label:hover .ql-fill {
    fill: #fff!important;
    stroke: none;
    }

    .ql-active .ql-fill {
    fill: #fff!important;
    stroke: none;
    }
    .ql-editor{
        color: #fff !important;
    }
}

.@{class-prefix}-wrap-text{
    word-wrap: break-word;
    word-break: break-all;
  }
  
.ant-table-tbody > tr > td {
    word-wrap: break-word !important;
    word-break: break-all !important;
}

.@{class-prefix}-wysiwyg-output{
    p, span, h1, h2, h3, h4, h5, h6, div{
      word-wrap: break-word;
      word-break: break-all;
    }
}

body.dark{
    .@{class-prefix}-wysiwyg-output{
        p, span, h1, h2, h3, h4, h5, h6, div{
          color:#fff !important;
        }
      }
}

.ql-editor{
    min-height:300px;
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
}

body.dark{
    .ant-table-cell-row-hover{
        background: @dark-90 !important;
    }
    .row-dragging {
        background: @dark-90 !important;
        border: 1px solid @dark-90;
      }
}
  
.row-dragging td {
    padding: 16px;
}
  
.row-dragging .drag-visible {
  visibility: visible;
}

.ant-form-item-label {
  text-align: left !important;
}

.antd-country-phone-input .ant-select{
    min-width: 110px !important;
}

.@{class-prefix}-wb-break-word{
    word-break: break-word;
  }

  .@{class-prefix}-upload-80 .ant-upload, .@{class-prefix}-upload-80 .ant-upload-list-picture-card-container{
    width: 80px;
    height: 80px;
  }