.head-example {
  width: 42px;
  height: 42px;
  border-radius: 7px;
  background: @primary-4;
  display: inline-block;
  vertical-align: middle;
}

.site-badge-count-4 .ant-badge-count {
  color: @b-60;
  background-color: @b-0;
  box-shadow: @input-focus;
}

.chat-indicator .ant-badge-status-processing {
  background-color: #ff0022 !important;

  &::after {
    border: 1px solid #ff0022 !important;
  }
}

.@{class-prefix}-badge-16 {
  &.ant-badge,
  .ant-badge-status-dot {
    width: 16px;
    height: 16px;
  }
}

// RTL
.ant-badge-rtl.ant-badge .ant-scroll-number-custom-component {
  transform: initial;
}
