.@{class-prefix}-jspdf-container{
  display: none
}
.@{class-prefix}-jspdf {
  padding: 32px;
  background-color: #fff;
  size: "A4";
  font-family: @font-family;
}

.@{class-prefix}-letterhead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .@{class-prefix}-letterhead-logo {
    height: 100px;
    padding: 0;
    margin-right: 24px;
  }

  .@{class-prefix}-company {
    margin-bottom: 8px;
    color: @b-100;
  }

  .@{class-prefix}-tagline {
    color: @b-100;
  }

  .@{class-prefix}-info {
    margin-bottom: 0;
    font-size: 14px;
  }
}
