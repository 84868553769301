.@{class-prefix}-notification {
  right: 0px;
  top: auto;
  bottom: 24px;

  box-sizing: border-box;
  padding: 0;
  color: #2d3436;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum";
  font-feature-settings: "tnum", "tnum";
  position: fixed;
  z-index: 1010;
  margin: 0 24px 0 0;
}

.@{class-prefix}-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 48px);
  margin-bottom: 16px;
  margin-left: auto;
  padding: 16px 24px;
  overflow: hidden;
  line-height: 1.5715;
  word-wrap: break-word;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 3px 20px 0px rgba(255, 255, 255, 0.06);
}

.@{class-prefix}-notification-notice-message {
  margin-bottom: 8px;
  color: #2d3436;
  font-size: 16px;
  line-height: 24px;
  padding-right: 24px;
}

.@{class-prefix}-notification-notice-description {
  font-size: 14px;
}

.@{class-prefix}-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
